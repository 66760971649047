<template>
  <div>
    <loginDialog ref="dialog"/>
    <div :title="$fanyi('改善意见')" ref="opinion" id="opinionContainer" class="opinionContainer flexAndCenterAndCenter"
         @click="isLogin">
      <img src="../../assets/index1.png">
      <div>{{ $fanyi('改善意见') }}</div>
    </div>
    <div class="newOpinionDialogContainer">
      <el-dialog
          :show-close="false"
          :visible.sync="dialogVisible"
          @close="closeDialogVisible"
          :close-on-click-modal="false"
          append-to-body
          :close-on-press-escape="false"
          custom-class="newOpinionDialogContainer"
          width="410px">
        <div class="dialogContentContainer">
          <div class="header">
            <div class="flexAndCenter">
              <div>{{ $fanyi('改善意见') }}</div>
              <i class="el-icon-close" @click="dialogVisible=false"></i>
            </div>
            <div class="borderContainer"></div>
          </div>
          <div class="fontSize14" style="margin-bottom: 24px">
            {{
              $fanyi('如果在使用本网站的过程中有不方便的地方或者想要改善的地方，请填写在下方文本框里，有图片也可进行上传，我们会尽可能的改善。')
            }}
          </div>
          <div class="fontSize14" style="margin-bottom: 24px">
            {{ $fanyi('请多多关照。') }}
          </div>
          <el-input v-model="client_remark" type="textarea" resize="none"/>
          <div class="fontSize14 color333" style="margin-bottom: 11px">{{ $fanyi('图片上传') }}</div>
          <el-upload
              :action="uploadUrl"
              list-type="picture-card"
              :file-list="fileList"
              :limit="3"
              :on-success="handleSuccess"
              :on-preview="handlePictureCardPreview"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleExceed"
              :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="hintText">
            <div>* {{ $fanyi('只支持上传格式为png，jpg，jpeg，pneg文件，最大可上传三张') }}</div>
          </div>
          <div class="flexAndCenterAndCenter footerBtnList">
            <div @click="dialogVisible=false">{{ $fanyi('取消') }}</div>
            <div @click="submitUserFeedback">{{ $fanyi('提交') }}</div>
          </div>
        </div>
      </el-dialog>
    </div>
    <el-dialog :visible.sync="imageDialogVisible" :close-on-click-modal="false"
               :close-on-press-escape="false"
               :width="imgWidth">
      <img :width="imgWidth" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import loginDialog from '@/components/public/loginDialog'

export default {
  name: "feedback",
  components: {
    loginDialog
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      dialogImageUrl: '',
      imageDialogVisible: false,
      imgWidth: '',
      client_remark: '',
    }
  },
  mounted() {
    // 控制聊天按钮可以自由移动
    var dv = this.$refs.opinion
    var x = 0
    var y = 0
    var l = 0
    var t = 0
    var isDown = false
    //鼠标按下事件
    dv.onmousedown = function (e) {
      //获取x坐标和y坐标
      x = e.clientX
      y = e.clientY
      //获取左部和顶部的偏移量
      l = dv.offsetLeft
      t = dv.offsetTop
      //开关打开
      isDown = true
      //设置样式
      dv.style.cursor = 'move'
      document.body.style.userSelect = 'none'
    }
    // 鼠标移动
    window.onmousemove = function (e) {
      // e.preventDefault()
      if (isDown == false) {
        return
      }
      //获取x和y
      var nx = e.clientX
      var ny = e.clientY
      //计算移动后的左偏移量和顶部的偏移量
      var nl = nx - (x - l)
      var nt = ny - (y - t)
      dv.style.left = nl + 'px'
      dv.style.top = nt + 'px'
      document.body.style.userSelect = 'auto'
    }
    //鼠标抬起事件
    dv.onmouseup = function (e) {
      isDown = false
      document.onmousemove = null
    }
  },
  methods: {
    //关闭弹窗清空数据
    closeDialogVisible() {
      this.fileList = [];
      this.client_remark = '';
    },
    //文件删除
    handleRemove(val) {
      const index = this.fileList.findIndex(item => item.url === val.url)
      this.fileList.splice(index, 1)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //提交客户的意见反馈
    submitUserFeedback() {
      if (this.client_remark.trim() === '') {
        this.client_remark = '';
        this.$message.error(this.$fanyi("请输入意见反馈"))
        return
      }
      this.$api.addFeedback({
        page_url: this.$route.fullPath,
        desc: this.client_remark,
        images: this.fileList
      }).then((res) => {
        if (res.code != 0) return;
        this.$message.success(res.msg);
        this.dialogVisible = false;
      });
    },
    beforeAvatarUpload(file) {
      const isJPG = ["image/jpeg", "image/png", "image/jpg", "image/pneg"].indexOf(file.type) != -1;
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }
    },
    //文件上传成功
    handleSuccess(res, file) {
      this.fileList.push(res.data)
    },
    //图片预览
    handlePictureCardPreview(file) {
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("正在获取图片信息"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      // 创建对象
      var img = new Image();
      // 改变图片的src
      img.src = file.response.data;
      img.onload = () => {
        let clientWidth = document.body.clientWidth;
        if (img.width > clientWidth) {
          img.width = clientWidth - 200;
        }
        this.imgWidth = img.width + 'px';
        this.dialogImageUrl = file.response.data;
        this.$forceUpdate();
        this.loading.close();
        this.imageDialogVisible = true;
      }
    },
    //是否登录
    isLogin() {
      if (this.$store.state.userInfo) {
        this.dialogVisible = true;
      } else {
        this.$refs.dialog.visible = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.opinionContainer {
  width: 80px;
  height: 80px;
  background: #B4272B;
  border-radius: 50%;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  left: 0;
  top: 10%;
  z-index: 1001;

  img {
    width: 35px;
    height: 35px;
    margin-bottom: 8px;
  }

  div {
    color: #FFFFFF;
    font-size: 12px;
  }
}
</style>
