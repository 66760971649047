/**
 * 返回年月日
 * @export
 * @param {Date} date
 * @param {string} [splitor='-']
 * @returns
 */
export function getDateMonth(date, splitor = '-') {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${year}${splitor}${addZeroPrefix(month)}`
}

/**
 * 返回年月
 * @export
 * @param {Date} date
 * @param {string} [splitor='-']
 * @returns
 */
export function getDate(date, splitor = '-') {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${year}${splitor}${addZeroPrefix(month)}${splitor}${addZeroPrefix(day)}`
}

/**
 * 返回时分秒/时分
 * @export
 * @param {*} date
 * @param {boolean} [withSecond=false]
 * @returns
 */
export function getTime(date, withSecond = false) {
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return withSecond ? `${addZeroPrefix(hour)}:${addZeroPrefix(minute)}:${addZeroPrefix(second)}` : `${hour}:${addZeroPrefix(minute)}`
}

export function getFullDate(date) {
    return `${getDate(date)} ${getTime(date)}`
}

export function isToday(date) {
    return date.toDateString() === new Date().toDateString()
}


/**
 * 个位数，加0前缀
 * @param {*} number
 * @returns
 */
function addZeroPrefix(number) {
    return number < 10 ? `0${number}` : number
}

// 当前月
export const getCurrentMonth = () => {
    var date = new Date();
    date.setDate(1);
    var month = parseInt(date.getMonth() + 1);
    var day = date.getDate();
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }

    return date.getFullYear() + '-' + month;
}
// 判断日期是否过期
export const pastDue = function (time) {
    let now = new Date()
    let dbtime = new Date(time)

    if (now.getFullYear() == dbtime.getFullYear() && now.getMonth() == dbtime.getMonth() && now.getDate() == dbtime.getDate()) {
        return false
    }

    return now > dbtime;
}

// 判断日期是否是今天
export const sameDate = function (time) {
    let now = new Date()
    let dbtime = new Date(time)
    now = now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate()
    dbtime = dbtime.getFullYear() + '-' + dbtime.getMonth() + '-' + dbtime.getDate()
    if (now == dbtime) {
        return true
    } else {
        return false
    }
}
// 当前日期
export const getCurrentDay = (timer) => {
    var date = new Date();
    if (timer) {
        var date = new Date(timer);
    }
    var month = parseInt(date.getMonth() + 1);
    var day = date.getDate();
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }

    return date.getFullYear() + '-' + month + '-' + day;
}

// 当前月的上一个月
export const getCurrentMonth_last = () => {
    var date = new Date();
    date.setDate(1);
    var month = parseInt(date.getMonth());
    var day = date.getDate();
    var year = date.getFullYear()
    if (month < 10) {
        month = '0' + month
    }
    if (month == '00') {
        month = 12
        year--
    }
    if (day < 10) {
        day = '0' + day
    }

    return year + '-' + month;
}
/**
 * 获取上一个月
 *
 * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
 */
export const getPreMonth = (date) => {
    var arr = date.split('-');
    var year = arr[0]; //获取当前日期的年份
    var month = arr[1]; //获取当前日期的月份
    var day = arr[2]; //获取当前日期的日
    var days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中月的天数
    var year2 = year;
    var month2 = parseInt(month) - 1;
    if (month2 == 0) {
        year2 = parseInt(year2) - 1;
        month2 = 12;
    }
    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    var t2 = year2 + '-' + month2 + '-' + day2;
    return t2;
}
/**
 * 获取下一个月
 *
 * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
 */
export const getNextMonth = (date) => {
    var arr = date.split('-');
    var year = arr[0]; //获取当前日期的年份
    var month = arr[1]; //获取当前日期的月份
    var day = arr[2]; //获取当前日期的日
    var days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中的月的天数
    var year2 = year;
    var month2 = parseInt(month) + 1;
    if (month2 == 13) {
        year2 = parseInt(year2) + 1;
        month2 = 1;
    }
    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    var t2 = year2 + '-' + month2 + '-' + day2;
    return t2;
}

// 当前月份第一天
export const getCurrentMonthFirst = () => {
    var date = new Date();
    date.setDate(1);
    var month = parseInt(date.getMonth() + 1);
    var day = date.getDate();
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
}

// 当前月份最后一天
export const getCurrentMonthLast = () => {
    var date = new Date();
    var currentMonth = date.getMonth();
    var nextMonth = ++currentMonth;
    var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    var oneDay = 1000 * 60 * 60 * 24;
    var lastTime = new Date(nextMonthFirstDay - oneDay);
    var month = parseInt(lastTime.getMonth() + 1);
    var day = lastTime.getDate();
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
}

// 指定月份第一天
export const getCurrentMonthFirst_zd = (date) => {
    if (date) {
        var date = new Date(date);
    } else {
        var date = new Date();
    }

    date.setDate(1);
    var month = parseInt(date.getMonth() + 1);
    var day = date.getDate();
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
}
// 对比两个时间
export const duibishijian = function (PlanStartTime, PlanEndTime) {
    const _PlanEndTime = new Date(PlanEndTime)
    const _PlanStartTime = new Date(PlanStartTime)
    return _PlanEndTime.getTime() < _PlanStartTime.getTime();
}
// 指定月份最后一天
export const getCurrentMonthLast_zd = (date) => {
    if (date) {
        var date = new Date(date);
    } else {
        var date = new Date();
    }
    var currentMonth = date.getMonth();
    var nextMonth = ++currentMonth;
    var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    var oneDay = 1000 * 60 * 60 * 24;
    var lastTime = new Date(nextMonthFirstDay - oneDay);
    var month = parseInt(lastTime.getMonth() + 1);
    var day = lastTime.getDate();
    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }
    return date.getFullYear() + '-' + month + '-' + day;
}
/**
 *获取当前时间
 *format=1精确到天
 *format=2精确到分
 */
export const getCurrentDate = (format) => {
    var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month = now.getMonth(); //得到月份
    var date = now.getDate(); //得到日期
    var day = now.getDay(); //得到周几
    var hour = now.getHours(); //得到小时
    var minu = now.getMinutes(); //得到分钟
    var sec = now.getSeconds(); //得到秒
    month = month + 1;
    if (month < 10) month = "0" + month;
    if (date < 10) date = "0" + date;
    if (hour < 10) hour = "0" + hour;
    if (minu < 10) minu = "0" + minu;
    if (sec < 10) sec = "0" + sec;
    var time = "";
    //精确到天
    if (format == 1) {
        time = year + "-" + month + "-" + date;
    }
    //精确到分
    else if (format == 2) {
        time = year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
    }
    return time;
}

// 获取指定日期之间的天数
export const daysDistance = (date1, date2) => {
    //parse() 是 Date 的一个静态方法 , 所以应该使用 Date.parse() 来调用，而不是作为 Date 的实例方法。返回该日期距离 1970/1/1 午夜时间的毫秒数
    date1 = Date.parse(date1);
    date2 = Date.parse(date2);
    //计算两个日期之间相差的毫秒数的绝对值
    var ms = Math.abs(date2 - date1);
    //毫秒数除以一天的毫秒数,就得到了天数
    var days = Math.floor(ms / (24 * 3600 * 1000));
    return days;
};