<template>
  <div class="submitErrorContainer">
    <loginDialog ref="dialog"/>
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="$fanyi('商品比較')"
               :visible.sync="commercialMatchDialogVisible" append-to-body width="1254px"
               @open="getCommercialMatchList">
      <div class="commercialMatchTableContainer">
        <div class="tableHeader">
          <div class="TopContainer">
            <div>合計 <span class="fontSize14 colorB4272B"
                            style="font-weight: bold">{{ commercialMatchList.length }}</span> 件商品
            </div>
            <div class="checkbox" style="margin-top:10px"
                 @click="hideIdenticalChecked=!hideIdenticalChecked;hideIdenticalCheckedChange()">
              <input v-model="hideIdenticalChecked" type="checkbox" @change.stop="hideIdenticalCheckedChange"/>
              <span>{{ $fanyi('隐藏相同点') }}</span>
            </div>
            <div class="checkbox" style="margin:10px 0"
                 @click="highlightDifferenceChecked=!highlightDifferenceChecked;highlightDifferenceCheckedChange()">
              <input v-model="highlightDifferenceChecked" type="checkbox"
                     @change.stop="highlightDifferenceCheckedChange"/>
              <span>{{ $fanyi('高亮不同点') }}</span>
            </div>
            <div class="btn" @click="exportExcel">{{ $fanyi('导出excel') }}</div>
          </div>
          <div
              :style="{'display':priceHiddenIdenticalStatus?'none':'block','background':priceHighlightDifferentStatus?'#FAF2F2':'transparent'}"
              class="tableLabel">
            {{ $fanyi('价格') }}
          </div>
          <div
              :style="{'display':titleHiddenIdenticalStatus?'none':'block','background':titleHighlightDifferentStatus?'#FAF2F2':'transparent'}"
              class="tableLabel">
            {{ $fanyi('商品标题') }}
          </div>
          <div
              :style="{'display':monthSoldHiddenIdenticalStatus?'none':'block','background':monthSoldHighlightDifferentStatus?'#FAF2F2':'transparent'}"
              class="tableLabel">
            {{ $fanyi('过去30天复购率') }}
          </div>
          <div
              :style="{'display':repurchaseRateHiddenIdenticalStatus?'none':'block','background':repurchaseRateHighlightDifferentStatus?'#FAF2F2':'transparent'}"
              class="tableLabel">
            {{ $fanyi('过去30天销量') }}
          </div>
          <div
              :style="{'display':linkHiddenIdenticalStatus?'none':'block','background':linkHighlightDifferentStatus?'#FAF2F2':'transparent'}"
              class="tableLabel">
            {{ $fanyi('商品链接') }}
          </div>
          <div
              :style="{'display':imgUrlHiddenIdenticalStatus?'none':'block','background':imgUrlHighlightDifferentStatus?'#FAF2F2':'transparent'}"
              class="tableLabel">
            {{ $fanyi('图片链接') }}
          </div>
          <div
              :style="{'display':shopNameHiddenIdenticalStatus?'none':'block','background':shopNameHighlightDifferentStatus?'#FAF2F2':'transparent'}"
              class="tableLabel"
              style="border-bottom:none">
            {{ $fanyi('店铺名称') }}
          </div>
        </div>
        <div class="commercialMatchListContainer">
          <div v-for="(item,index) in commercialMatchList" :key="index" class="tableItemContainer">
            <div class="TopContainer">
              <div class="goodInfoContainer">
                <div class="header">
                  <div class="headerLeft" @click="goodsReplace(index)">
                    <img alt="" src="../../../assets/1688/pushpinIcon.svg">
                    <div>{{ $fanyi('固定在左侧') }}</div>
                  </div>
                  <div class="headerRight" @click="deleteGoods(index)">x</div>
                </div>
                <div class="footer">
                  <img :src="item.imgUrl" alt="">
                  <div>
                    <router-link
                        :to="{path:'/ProductDetails',query: {goods_id: item.goodsId,fromPlatform:item.shopType}}"
                        class="u-line-3"
                        target="_blank">
                      {{ item.title }}
                    </router-link>
                    <div v-if="item.goodsId != undefined && item.shopType != undefined"
                         class="footerOperationContainer">
                      <button v-if="!item.goodsFavoriteStatus" class="toFavorite" @click="shopAddFavorite(item)">
                        <img alt="" src="../../../assets/1688/collect.png">
                        {{ $fanyi("收藏") }}
                      </button>
                      <button v-else class="toFavorite" @click="favoriteShopDelete(item)">
                        <img alt="" src="../../../assets/SearchResultsPage/Collected.png"
                             style="width: 16px;height: 16px">
                        {{ $fanyi("取消收藏") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
                :style="{'display':priceHiddenIdenticalStatus?'none':'flex','background':priceHighlightDifferentStatus?'#FAF2F2':'transparent'}"
                class="tableLabel">
              <div class="fontSize14 colorB4272B" style="font-weight: bold">
                {{ Number(item.price) }}元（{{ (Number(item.price) * exchangeRate).toFixed(0) }}円）
              </div>
            </div>
            <div
                :style="{'display':titleHiddenIdenticalStatus?'none':'flex','background':titleHighlightDifferentStatus?'#FAF2F2':'transparent'}"
                class="tableLabel">
              <div class="u-line" style="width: 300px;">{{ item.title }}</div>
            </div>
            <div
                :style="{'display':monthSoldHiddenIdenticalStatus?'none':'flex','background':monthSoldHighlightDifferentStatus||(repurchaseStatus==true&&(item.repurchaseRate!=''&&item.repurchaseRate!=undefined))?'#FAF2F2':'transparent'}"
                class="tableLabel">
              {{ item.repurchaseRate }}
            </div>
            <div
                :style="{'display':repurchaseRateHiddenIdenticalStatus?'none':'flex','background':repurchaseRateHighlightDifferentStatus||(salesStatus==true&&(item.monthSold!=''&&item.monthSold!=undefined))?'#FAF2F2':'transparent'}"
                class="tableLabel">
              {{ item.monthSold }}
            </div>
            <div
                :style="{'display':linkHiddenIdenticalStatus?'none':'flex','background':linkHighlightDifferentStatus?'#FAF2F2':'transparent'}"
                class="tableLabel">
              <div class="u-line-2" style="width: 304px;">{{ item.link }}</div>
            </div>
            <div
                :style="{'display':imgUrlHiddenIdenticalStatus?'none':'flex','background':imgUrlHighlightDifferentStatus?'#FAF2F2':'transparent'}"
                class="tableLabel">
              <div class="u-line-2" style="width: 304px;">{{ item.imgUrl }}</div>
            </div>
            <div
                :style="{'display':shopNameHiddenIdenticalStatus?'none':'flex','background':shopNameHighlightDifferentStatus?'#FAF2F2':'transparent'}"
                class="tableLabel"
                style="border-bottom:none">
              <div>{{ item.shopName }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {exportToExcel} from "@/utlis/ExportExcelWithPic";
import loginDialog from "@/components/public/loginDialog.vue";

export default {
  components: {loginDialog},
  data() {
    return {
      commercialMatchDialogVisible: false,
      commercialMatchList: [],
      priceHiddenIdenticalStatus: false,
      priceHighlightDifferentStatus: false,
      titleHiddenIdenticalStatus: false,
      titleHighlightDifferentStatus: false,
      monthSoldHiddenIdenticalStatus: false,
      monthSoldHighlightDifferentStatus: false,
      repurchaseRateHiddenIdenticalStatus: false,
      repurchaseRateHighlightDifferentStatus: false,
      hideIdenticalChecked: false,
      highlightDifferenceChecked: false,
      linkHiddenIdenticalStatus: false,
      linkHighlightDifferentStatus: false,
      imgUrlHiddenIdenticalStatus: false,
      imgUrlHighlightDifferentStatus: false,
      shopNameHiddenIdenticalStatus: false,
      shopNameHighlightDifferentStatus: false,
      salesStatus: false,
      repurchaseStatus: false,
    }
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    }
  },
  methods: {
    //获取比较列表
    getCommercialMatchList() {
      if (localStorage.getItem('commercialMatchList') != null) {
        let arr = [];
        arr = JSON.parse(localStorage.getItem('commercialMatchList'));
        arr.forEach((item) => {
          item.goodsFavoriteStatus = false;
          if (item.goodsId != undefined && item.shopType != undefined) {
            this.$api.favoriteGoodsCheckIn({
              goods_id: [item.goodsId],
              shop_type: item.shopType
            })
            .then((res) => {
              if (res.code != 0) return false;
              item.goodsFavoriteStatus = res.data[0].inFavorite;
            });
          }
        })
        this.commercialMatchList = arr;
        this.$store.commit('setCommercialMatchGoodsCount', this.commercialMatchList.length);
      }
    },
    //替换位置
    goodsReplace(index) {
      [this.commercialMatchList[0], this.commercialMatchList[index]] = [this.commercialMatchList[index], this.commercialMatchList[0]]
      this.$forceUpdate();
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('对比列表替换位置功能使用量统计');
      }
    },
    //导出excel
    exportExcel() {
      const tHeader = ['写真', '商品タイトル', '価格', '過去30日のリピード率', '過去30日の販売数', '商品リンク', '画像url', '店舗名']
      const filterVal = ['imgUrl', 'title', 'price', 'repurchaseRate', 'monthSold', 'link', 'imgUrl', 'shopName']
      const data = this.formatJson(filterVal, this.commercialMatchList)
      this.getExcelInfo(tHeader, data, '商品比較' + new Date().toLocaleDateString())
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('对比列表导出excel功能使用量统计');
      }
    },
    //商品收藏
    shopAddFavorite(val) {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      let datas = {
        shop_type: val.shopType,
        goods_id: val.goodsId,
        title: val.titleC,
        image_url: val.imgUrl,
        price: val.price,
      };
      this.$api.favoriteGoodsAdd(datas).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        val.goodsFavoriteStatus = !val.goodsFavoriteStatus;
        this.$message.success(this.$fanyi(res.msg))
        this.$forceUpdate();
      });
    },
    //商品取消收藏
    favoriteShopDelete(val) {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      let arr = [];
      arr.push({
        shop_type: val.shopType,
        goods_id: val.goodsId
      })
      this.$api.favoriteGoodsDelete({delete: arr}).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        val.goodsFavoriteStatus = !val.goodsFavoriteStatus;
        this.$message.success(this.$fanyi(res.msg))
        this.$forceUpdate();
      });
    },
    //导出excel
    getExcelInfo(theadData, tbodyData, dataname) {
      let th_len = theadData.length // 表头的长度
      let tb_len = tbodyData.length // 记录条数
      let width = 100 // 设置图片大小
      let height = 120

      // 添加表头信息
      let thead = '<thead><tr>'
      for (let i = 0; i < th_len; i++) {
        thead += '<th>' + theadData[i] + '</th>'
      }
      thead += '</tr></thead>'
      // 添加每一行数据
      let tbody = '<tbody>'
      for (let i = 0; i < tb_len; i++) {
        tbody += '<tr>'
        let row = tbodyData[i] // 获取每一行数据
        for (let key in row) {
          if (key == 0) { // 如果为图片，则需要加div包住图片
            //
            tbody += '<td style="width:' + width + 'px; height:' + height + 'px; text-align: center; vertical-align: middle"><div style="display:inline"><img src=\'' + row[key] + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '></div></td>'
          } else {
            tbody += '<td style="text-align:center">' + row[key] + '</td>'
          }
        }
        tbody += '</tr>'
      }
      tbody += '</tbody>'
      let table = thead + tbody
      // 导出表格
      exportToExcel(table, dataname)
    },
    //导出excel
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },
    //隐藏相同
    hideIdenticalCheckedChange() {
      if (this.commercialMatchList.length > 1) {
        if (this.hideIdenticalChecked) {
          let price = [];
          let title = [];
          let monthSold = [];
          let repurchaseRate = [];
          let link = [];
          let imgUrl = [];
          let shopName = [];
          this.commercialMatchList.forEach((item) => {
            price.push(item.price);
            title.push(item.title);
            monthSold.push(item.monthSold);
            repurchaseRate.push(item.repurchaseRate);
            link.push(item.link)
            imgUrl.push(item.imgUrl)
            shopName.push(item.shopName)
          })
          this.priceHiddenIdenticalStatus = Array.from(new Set(price)).length == 1;
          this.titleHiddenIdenticalStatus = Array.from(new Set(title)).length == 1;
          this.monthSoldHiddenIdenticalStatus = Array.from(new Set(monthSold)).length == 1;
          this.repurchaseRateHiddenIdenticalStatus = Array.from(new Set(repurchaseRate)).length == 1;
          this.linkHiddenIdenticalStatus = Array.from(new Set(link)).length == 1;
          this.imgUrlHiddenIdenticalStatus = Array.from(new Set(imgUrl)).length == 1;
          this.shopNameHiddenIdenticalStatus = Array.from(new Set(shopName)).length == 1;
        } else {
          this.priceHiddenIdenticalStatus = false;
          this.titleHiddenIdenticalStatus = false;
          this.monthSoldHiddenIdenticalStatus = false;
          this.repurchaseRateHiddenIdenticalStatus = false;
          this.linkHiddenIdenticalStatus = false;
          this.imgUrlHiddenIdenticalStatus = false;
          this.shopNameHiddenIdenticalStatus = false;
        }
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('对比列表隐藏相同功能使用量统计');
        }
      }
    },
    //高亮不同
    highlightDifferenceCheckedChange() {
      if (this.commercialMatchList.length > 1) {
        if (this.highlightDifferenceChecked) {
          let price = [];
          let title = [];
          let monthSold = [];
          let repurchaseRate = [];
          let link = [];
          let imgUrl = [];
          let shopName = [];
          this.commercialMatchList.forEach((item) => {
            price.push(item.price);
            title.push(item.title);
            monthSold.push(item.monthSold);
            repurchaseRate.push(item.repurchaseRate);
            link.push(item.link)
            imgUrl.push(item.imgUrl)
            shopName.push(item.shopName)
          })
          this.priceHighlightDifferentStatus = Array.from(new Set(price)).length > 1;
          this.titleHighlightDifferentStatus = Array.from(new Set(title)).length > 1;
          this.monthSoldHighlightDifferentStatus = Array.from(new Set(monthSold)).length > 1;
          this.repurchaseRateHighlightDifferentStatus = Array.from(new Set(repurchaseRate)).length > 1;
          this.linkHighlightDifferentStatus = Array.from(new Set(link)).length > 1;
          this.imgUrlHighlightDifferentStatus = Array.from(new Set(imgUrl)).length > 1;
          this.shopNameHighlightDifferentStatus = Array.from(new Set(shopName)).length > 1;
        } else {
          this.priceHighlightDifferentStatus = false;
          this.titleHighlightDifferentStatus = false;
          this.monthSoldHighlightDifferentStatus = false;
          this.repurchaseRateHighlightDifferentStatus = false;
          this.linkHighlightDifferentStatus = false;
          this.imgUrlHighlightDifferentStatus = false;
          this.shopNameHighlightDifferentStatus = false;
        }
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('对比列表高亮不同功能使用量统计');
        }
      }
    },
    //删除比较商品
    deleteGoods(index) {
      this.commercialMatchList.splice(index, 1);
      localStorage.setItem("commercialMatchList", JSON.stringify(this.commercialMatchList));
      this.getCommercialMatchList();
    },
    //清空商品比较列表
    clearGoods() {
      localStorage.setItem("commercialMatchList", JSON.stringify([]));
      this.getCommercialMatchList();
    },
    //高亮有销量
    highlightSales() {
      this.salesStatus = !this.salesStatus;
    },
    //高亮有复购
    highlightRepurchase() {
      this.repurchaseStatus = !this.repurchaseStatus;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../css/CommoditySearch.scss";

.footerOperationContainer {
  display: flex;
  align-items: center;
  padding-left: 12px;
  justify-content: flex-end;
  margin-top: 10px;

  button, div {
    height: 26px;
    background: #B4272B;
    border-radius: 4px;
    padding: 0 6px;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      margin-right: 5px !important;
      width: 16px !important;
      height: 16px !important;
      min-width: 16px !important;
      min-height: 16px !important;
      border-radius: 0 !important;
    }
  }
}
</style>