<template>
  <div id="sideBar">
    <loginDialog ref="dialog"/>
    <div ref="chitchat" class="chitchat" id="dragbtn" @click.stop="chitChatShow" v-if="status===true">
      <img src="../../assets/hometop/message.svg" alt=""/>
    </div>
    <img src="../../assets/chat_me.png" style="cursor: pointer" v-if="touristStatus===true" alt="" @click="lianxi(1)">
    <div id="topBtn" class="sideBarGgroup">
      <div :class="isHover == 7 ? 'sideBarGgroupOpt top' : 'sideBarGgroupOpt'" @mouseenter.stop="isHover = 7"
           @mouseleave.stop="isHover = -1" @click="toTop" style="cursor: pointer">
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>
  </div>
</template>

<script>
import loginDialog from "@/components/public/loginDialog";

export default {
  data() {
    return {
      isHover: -1,
      userDataInfo: {},
      hide: false,
      status: false,
      touristStatus: false,
    }
  },
  components: {loginDialog},
  computed: {},
  mounted() {
    if ((location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1) && localStorage.getItem('japan_user_token') == null) {
      this.touristStatus = true;
    }
  },
  methods: {
    lianxi(val) {
      if (val === 1) {
        if (this.$store.state.userInfo != null) {
          let user_token = localStorage.getItem('japan_user_token')
          let iTop = (window.screen.availHeight - 30 - 700) / 2
          //获得窗口的水平位置 +8615068066206
          let iLeft = (window.screen.availWidth - 10 - 1260) / 2
          window.open(
              'https://rakuchat.rakumart.com/client_web/#/?id=' + this.$store.state.userInfo.operation_id + '&token=' +
              user_token,
              this.$store.state.userInfo,
              'height=700, width=1260, left=' +
              iLeft +
              ', top=' +
              iTop +
              ', toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no'
          )
        } else {
          window.open('https://rakuchat.rakumart.com/visitor_web/#/')
        }
      } else if (this.$store.state.userInfo != null && val === 2) {

      }
    },
    chitChatShow() {
      this.hide = !this.hide;
      this.lianxi(1);
    },
    toTop() {
      let timer = setInterval(function () {
        let osTop =
            document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 60)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 2)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../css/mixin';

#sideBar {
  // option选项的大小
  $sideBarOptSize: 50px;
  // width: $sideBarOptSize;
  position: fixed;
  right: 0;
  top: 90%;
  z-index: 10;

  .sideBarGgroup {
    display: none;
    // 侧边栏的圆角角度
    margin-bottom: 50px;

    .sideBarGgroupOpt {
      font-weight: bold;
      transform: translate(50%, 0);

      &.top {
        color: $homePageSubjectColor;
      }

      .el-icon-arrow-up {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 5px;

        &:hover {
          color: $homePageSubjectColor;
        }
      }

      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: $sideBarOptSize;
      height: $sideBarOptSize;
      $sideBarIconSize: 50px;
      box-shadow: 0px 9px 8px 0px rgba(41, 41, 41, 0.11);

      img {
        width: $sideBarIconSize;
        height: $sideBarIconSize;
      }
    }
  }
}

.chitchat {
  position: relative;
  bottom: 00%;
  transform: translate(0, -50%);
  width: 50px;
  z-index: 9999999;
  height: 50px;
  background: #B4272B !important;
  cursor: pointer;
  // .xiaoxi {
  //   min-width: 16px;
  //   height: 16px;
  //   left: 25px;
  //   top: 0px;
  //   position: absolute;
  //   font-size: 12px;
  //   font-family: Roboto-Regular, Roboto;
  //   font-weight: 400;
  //   color: #FFFFFF;
  //   line-height: 16px;
  //   text-align: center;
  //   background: #000000;
  //   border-radius: 10px;
  //   padding: 0 5px;
  // }
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 14px 20px;

  .rk {
    z-index: -10;
    opacity: 0;
    top: 0;
    position: absolute;
    transition: top 0.8s;
    // transform: translate(0, -50%);
    right: 0px;
    background: #ffffff;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: #0070be;
    display: none;
    display: flex;
    align-items: center;
    justify-content: center;
    // .rkxiaoxi {
    //   min-width: 16px;
    //   height: 16px;
    //   left: 25px;
    //   top: 0px;
    //   position: absolute;
    //   font-size: 12px;
    //   font-family: Roboto-Regular, Roboto;
    //   font-weight: 400;
    //   color: #FFFFFF;
    //   line-height: 16px;
    //   text-align: center;
    //   background: #FF730B;
    //   border-radius: 10px;
    //   padding: 0 5px;
    // }
    // transition: all 0.8s;
    img {
      width: 28px;
      height: 28px;
    }
  }

  .lainxi {
    // bottom: 0;
    top: 0;
    border-radius: 50%;
    position: absolute;
    z-index: -9;
    opacity: 0;
    // transform: translate(0, -50%);
    right: 0px;
    display: none;
    width: 50px;
    height: 50px;
    box-shadow: 0px 6px 10px 0px rgba(41, 41, 41, 0.06);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: top 0.8s linear;
    // .langxiaoxi {
    //   min-width: 16px;
    //   height: 16px;
    //   left: 25px;
    //   top: 0px;
    //   position: absolute;
    //   font-size: 12px;
    //   font-family: Roboto-Regular, Roboto;
    //   font-weight: 400;
    //   color: #FFFFFF;
    //   line-height: 16px;
    //   text-align: center;
    //   background: #FF730B;
    //   border-radius: 10px;
    //   padding: 0 5px;
    // }
    img {
      width: 28px;
      height: 28px;
      // box-shadow: 0px 6px 10px 0px rgba(41, 41, 41, 0.06);
    }
  }

  .el-icon-close {
    color: #fff;
    font-size: 30px;
    // transform: rotate(360deg);
    // transition: all 0.8s;
  }

  .modle {
    top: -60px;
    // display: block;
    opacity: 1;
    transition: all 0.8s;

    img {
      width: 48px;
      height: 48px;

      // box-shadow: 0px 6px 10px 0px rgba(41, 41, 41, 0.06);
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  .raku {
    top: -120px;
    // display: block;
    opacity: 1;
    transition: all 0.8s;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 48px;
      height: 48px;
    }
  }

  img {
    width: 28px;
    height: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.8s;
    transform: rotate(360deg);
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #404040;
  }
}

/deep/ .el-dialog__body {
  padding: 25px 60px 30px 30px !important;

  span {
    font-size: 16px !important;
  }
}
</style>
