import { render, staticRenderFns } from "./commercialMatch.vue?vue&type=template&id=8a8de762&scoped=true&"
import script from "./commercialMatch.vue?vue&type=script&lang=js&"
export * from "./commercialMatch.vue?vue&type=script&lang=js&"
import style0 from "./commercialMatch.vue?vue&type=style&index=0&id=8a8de762&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a8de762",
  null
  
)

export default component.exports