<template>
  <div class="publicLoginDialogContainer">
    <el-dialog
        class="el-dialog-cus"
        :visible.sync="visible"
        append-to-body
        custom-class="publicLoginDialogContainer"
        width="530px">
      <div class="logoContainer flexAndCenterAndCenter">
        <img src="../../assets/logo2.png">
      </div>
      <div class="loginContainer">
        <!-- 账号 -->
        <div :class="loginNameFocusStatus===true?'newInput':'input'" style="margin-top: 52px">
          <div class="label">{{ $fanyi("账号") }}</div>
          <el-input v-model="loginData.login_name"
                    :placeholder="$fanyi('请输入账号或手机号或邮箱')"
                    @focus="loginNameFocus" @blur="loginNameBlur"/>
        </div>
        <!-- 密码 -->
        <div :class="passwordFocusStatus===true?'newInput':'input'">
          <div class="label">{{ $fanyi("密码") }}</div>
          <el-input :type="inputType" style="position: relative" :placeholder="$fanyi('请输入密码')"
                    v-model="loginData.password" @focus="passwordFocus"
                    @blur="passwordBlur"/>
          <img :src="passwordIcon" @click="updatePasswordIcon" class="mimaIcon">
        </div>
        <!--登录  -->
        <div class="submit">
          <el-button type="primary" @click.stop="login">{{ $fanyi("登录") }}</el-button>
        </div>
        <!--      忘记密码与保持登录状态-->
        <div class="flexAndCenter operationContainer">
          <div class="flexAndCenter">
            <input type="checkbox"
                   style="margin:0"
                   v-model="Save_the_password"
            />
            <div class="text">{{ $fanyi("保持登录状态") }}</div>
          </div>
          <div class="forgetThePassword" @click="forgetThePasswordPageJump">{{ $fanyi("忘记密码") }}?</div>
        </div>
        <!--注册-->
        <div class="registerContainer">
          <div class="flexAndCenter">
            <div class="colorB4272B">{{ $fanyi("还没有账号") }}？</div>
            <div class="colorB4272B cursorPointer fontWeightBold" style="text-decoration: underline;"
                 @click="$fun.routerToPage('/register')">{{
                $fanyi("注册")
              }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      Save_the_password: false,
      loginData: {
        login_name: localStorage.getItem("jpusername") ? localStorage.getItem("jpusername") : "",
        password: localStorage.getItem("jppassword") ? localStorage.getItem("jppassword") : "",
      },
      loginNameFocusStatus: false,
      passwordFocusStatus: false,
      inputType: 'password',
      passwordStatus: false,
      passwordIcon: require('../../assets/mima-2.png'),
    };
  },

  created() {
    let that = this;
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.login(); // 登录方法名
        return false;
      }
    };
    localStorage.getItem("jpkeep") == 1 ? (this.Save_the_password = true) : (this.Save_the_password = false);
  },
  methods: {
    login() {
      if (this.visible === true) {
        if (this.loginData.login_name === '') {
          this.$message.error(this.$fanyi("请输入账号或手机号或邮箱"));
          return
        }
        if (this.loginData.password === '') {
          this.$message.error(this.$fanyi("请输入密码"));
          return
        }
        if (this.Save_the_password == true) {
          localStorage.setItem("jpusername", this.loginData.login_name);
          localStorage.setItem("jppassword", this.loginData.password);
          localStorage.setItem("jpkeep", 1);
        } else {
          localStorage.removeItem("jpusername");
          localStorage.removeItem("jppassword");
          localStorage.removeItem("jpkeep");
        }
        this.$api.logins({
          account: this.loginData.login_name,
          password: this.loginData.password,
          client_tool: 1,
        }).then((res) => {
          if (!res.success) {
            this.$confirm(
                res.msg,
                '注意',
                {
                  confirmButtonText: this.$fanyi('确定'), //确定
                  cancelButtonText: this.$fanyi('取消'), //取消
                  type: "warning",
                  center: true,
                }
            ).then(() => {

            })
            .catch(() => {
            });
            return
          }
          localStorage.setItem("japan_user_token", res.data.userToken);
          this.ordersSum();
          this.logisticsSum();
          this.porderSum();
          this.warehouseSum();
          this.$api.goodsToCartNum().then(res => {
            if (res.code == 0) {
              this.$store.commit('setcartCount', res.data.dataCount);
            } else {
              this.$store.commit('setcartCount', 0);
            }
          })
          this.$api.japanGetUserInfo().then((res) => {
            this.$store.commit("userData", res.data);
            this.visible = false;
          });
        });
      }
    },
    //更新输入框密码展示状态
    updatePasswordIcon() {
      this.passwordStatus = !this.passwordStatus;
      this.passwordStatus === true ? this.inputType = 'text' : this.inputType = 'password';
      this.passwordStatus === true ? this.passwordIcon = require('../../assets/mima-1.png') : this.passwordIcon = require('../../assets/mima-2.png');
    },
    //登录名获得焦点
    loginNameFocus() {
      this.loginNameFocusStatus = true;
    },
    //登录名失去焦点
    loginNameBlur() {
      this.loginNameFocusStatus = false;
    },
    //密码获得焦点
    passwordFocus() {
      this.passwordFocusStatus = true;
    },
    //密码名失去焦点
    passwordBlur() {
      this.passwordFocusStatus = false;
    },
    //获取中国国内物流各个状态的数量
    logisticsSum() {
      this.$api.chinaLogisticsListTableNum().then((res) => {
        if (res.code != 0) {
          return
        }
        this.$store.commit("setChinaLogisticsListTableNumList", res.data);
      })
    },
    //获取配送单各个状态数量
    porderSum() {
      this.$api.storageDeliverNum().then((res) => {
        if (res.code != 0) {
          return
        }
        this.$store.commit("setStorageDeliverNumList", res.data);
      })
    },
    //获取仓库各个状态的数量
    warehouseSum() {
      this.$api.stockTableNum().then((res) => {
        if (res.code != 0) {
          return
        }
        this.$store.commit("setWarehouseSumList", res.data);
      })
    },
    //获取订单列表各个状态数量
    ordersSum() {
      this.$api.ordersSum().then((res) => {
        if (res.code != 0) {
          let arr = [];
          this.$api.problemGoodsOrderNum().then((res) => {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].name === '待回复') {
                arr.push(res.data[i]);
                this.$store.commit("setOrdersSumList", arr);
                break;
              }
            }
          });
        } else {
          this.$store.commit("setOrdersSumList", res.data);
        }
      })
    },
    //忘记密码页面跳转
    forgetThePasswordPageJump() {
      let href = "/ForgetThePassword";
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.loginDialogContainer {
  /deep/ .el-dialog__header, /deep/ .el-dialog__body {
    padding: 0 !important;
  }

  /deep/ .el-dialog__close {
    color: #B4272B;
  }

  .logoContainer {
    margin-bottom: 65px;
    padding-top: 60px;
    width: 100%;

    img {
      width: 229px;
    }
  }
}
</style>
